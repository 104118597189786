export function handleNewBinary(
	binInput,
	binMax,
	setBinInput,
	decomposeNumber
) {
	if (binInput < 0 || binInput > binMax) setBinInput(0);
	if (binInput === 0) {
		document.getElementById('decrease').disabled = true;
	} else {
		document.getElementById('decrease').disabled = false;
	}
	if (binInput === binMax) {
		document.getElementById('increase').disabled = true;
	} else {
		document.getElementById('increase').disabled = false;
	}
	document.getElementById('binInput').value = binInput;
	decomposeNumber(binInput);
}
