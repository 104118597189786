import React, { useEffect, useRef, useState } from 'react';
import { handleNewBinary } from './handleNewBinary';
import { SpeedInsights } from '@vercel/speed-insights/react';
import ReactGa4 from 'react-ga4';
import { Logo } from '@146north/logotype';
import './App.css';
import './Binary.css';
import './Dip.css';

ReactGa4.initialize('G-RT1RQE23SQ');

function App() {
	let switches = [];
	let binMax = 255;

	const inputRef = useRef(null);
	const [mode, setMode] = useState('Rco');
	const [binInput, setBinInput] = useState(0);

	if (mode === 'Aptus') {
		switches = [16, 8, 4, 2, 1, 0, 0, 0];
		binMax = 31;
	}
	if (mode === 'Rco') {
		switches = [1, 2, 4, 8, 16, 32, 64, 128];
		binMax = 255;
	}

	useEffect(() => {
		handleNewBinary(binInput, binMax, setBinInput, decomposeNumber);
	}, [binInput]);

	/**
	 * Decomposes a number into its binary components and updates the corresponding checkboxes.
	 * @param {number} number - The number to decompose.
	 * @returns {void}
	 */
	function decomposeNumber(number) {
		const components = [];
		for (let i = 7; i >= 0; i--) {
			let binaryComponent = Math.pow(2, i);
			if (number >= binaryComponent) {
				components.push(binaryComponent);
				number -= binaryComponent;
			}
		}
		const checkboxes = document.querySelectorAll('input[type="checkbox"]');
		checkboxes.forEach((checkbox) => {
			let switchValue = checkbox.value;
			if (components.includes(parseInt(switchValue))) {
				checkbox.checked = true;
			} else {
				checkbox.checked = false;
			}
		});
	}

	/**
	 * Increases the value of binInput by 1.
	 */
	function increaseBinInput() {
		setBinInput(binInput + 1);
	}

	/**
	 * Decreases the value of binInput by 1.
	 */
	function decreaseBinInput() {
		setBinInput(binInput - 1);
	}

	/**
	 * Updates the binary input value.
	 */
	function go() {
		setBinInput(parseInt(document.getElementById('binInput').value));
	}

	/**
	 * Handles the change event for the dip input.
	 * @param {Event} e - The change event object.
	 */
	function handleDipChange(e) {
		if (e.target.checked) {
			setBinInput(binInput + parseInt(e.target.value));
		} else {
			setBinInput(binInput - parseInt(e.target.value));
		}
	}

	/**
	 * Sets the mode and updates the UI based on the selected mode.
	 * @param {string} modell - The selected mode.
	 * @returns {Function} - A function that updates the UI based on the selected mode.
	 */
	function modeSelect(modell) {
		return () => {
			const modes = document.querySelectorAll('.modeSelect');
			modes.forEach((mode) => {
				if (mode.id === modell) {
					console.log(mode.id);
					mode.classList.add('active');
				} else {
					mode.classList.remove('active');
				}
			});
			setMode(modell);
			setBinInput(0);
		};
	}

	return (
		<div className="App">
			<header>
				<Logo text="Binär adressering!" logosize="60%" margin="8vh,4vh" />
			</header>
			<main>
				<p>
					Binäradressering på Aptus 700 och RCO kan vara klurigt, här får du
					hjälp med det. <br />
					Skriv in adressen i fältet nedan så ser du ska dippa, eller om om du
					vill ta reda på vad en central är adresserad till så kan du dippa
					nedan och få adressen utskriven.
				</p>
				<div className="setMode">
					<div></div>
					<div>
						Mode:
						<span
							className="modeSelect"
							id="Aptus"
							onClick={modeSelect('Aptus')}
						>
							Aptus
						</span>{' '}
						|{' '}
						<span
							className="modeSelect active"
							id="Rco"
							onClick={modeSelect('Rco')}
						>
							RCO
						</span>
					</div>
				</div>
				<div className="switch">
					{switches.map((switchValue, index) => (
						<div className="switch-item" key={index}>
							<div className="switch-text">
								{switches[index] !== 0 ? switches[index] : 'N/A'}
							</div>
							<div className="checkbox-wrapper-2">
								<label for={`switch-${index + 1}`}>{switchValue}</label>
								<input
									type="checkbox"
									value={switchValue}
									className="sc-gJwTLC ikxBAC"
									id={`switch-${index + 1}`}
									pattern="[0-9]"
									onChange={(e) => {
										handleDipChange(e);
									}}
								></input>
							</div>
							<div className="switch-text">{index + 1}</div>
						</div>
					))}
				</div>
				{mode === 'Aptus' && (
					<>
						<p className="observera">
							<span className="bold">OBS:</span> Dip 6-8 på Aptus är förbehållna
							andra funktioner så som återställning av programeringskod m.m.
							<br />
							Dessa ska oftast lämnas av. <br />
							Högsta möjliga address är 31.
						</p>
					</>
				)}

				<div className="input">
					<button id="increase" onClick={increaseBinInput}>
						+
					</button>

					<input
						type="number"
						onChange={go}
						id="binInput"
						ref={inputRef}
						inputMode="numeric"
					></input>
					<button id="decrease" onClick={decreaseBinInput}>
						-
					</button>
				</div>
			</main>
			<footer>
				<p>
					<a href="https://www.146.se">&copy; 2023 Henrik Danielsson</a>
				</p>
			</footer>
		</div>
	);
}

export default App;
